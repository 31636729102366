import {
  ATRIUM, LECTURE_HALL, WTP, BIOGAS /*,  LOBBY */
} from "./rooms";

const APP_DATA = {
  scenes: [
    ...ATRIUM,
    ...LECTURE_HALL,
    ...WTP,
    ...BIOGAS, /*
    ...LOBBY */
  ],
  name: "THI virtuelle Fachtagung",
  settings: {
    mouseViewMode: "drag",
    autorotateEnabled: false,
    fullscreenButton: false,
    viewControlButtons: false,
  },
};

export default APP_DATA;