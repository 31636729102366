const NAV_DATA = {
  firstLevelSubmenu: {
    label: "Stellwände",
  },
  navbarLeft: [
/*    {
      key: "home",
      label: "",
      icon: "home-256",
      action: "sceneLink",
      sceneId: "atrium",
    },*/
    {
      key: "back",
      label: "Zurück zur Empfangshalle",
      icon: "arrow-back-256",
      action: "goBack",
    },
  ],
  navbarRight: [
    {
      key: "lecture",
      label: "Zur Hauptbühne",
      /*action: "sceneLink",
      sceneId: "0-lecture-hall",*/
      action: "openLink",
      sceneId: "https://meet.teamgeist.com/v/waermewende_mitgestalten-optimierung_von_waermenetzen/live/mainstage"
    },
  ],
};

export default NAV_DATA;
